//FOOTER
var mainFooter = (function(){
	var footerAccordions,
	
	init = function(){
		footerAccordions = new getAccordions4i();
	       footerAccordions.init(document.querySelector(".mainSiteFooter"));
	       openCloseAccordions();
	       window.addEventListener("resize", openCloseAccordions);
	},
	openCloseAccordions = function(){		
		if(window.innerWidth < 600){
			//if mobile then collapse
			footerAccordions.close(document.getElementById("mediaSection"));
			footerAccordions.close(document.getElementById("worldwideSection"));
		}else{
			//open all
			footerAccordions.openAll(document.querySelector(".mainSiteFooter"));
		}
	};
	return{
		Init: init
	};
})();

(function(){
	if(document.getElementById("corporateSection")){
		mainFooter.Init();
	}
})();