/* Still needed since safari and firefox do not support psuedo css on <img /> -- 8/3/23
** CSS located lower in this file for future use, fingers crossed.
**---------------------------------------------*/

// call bindImages4i() any time new images are dynamically added to the page and need to be observed
function observeImageErrors(observerEntries, observer){
    	for(let observerEntry of observerEntries){
        	let image = observerEntry.target;
        
        	if(observerEntry.isIntersecting && !image.hasPreloader){
            		image.hasPreloader = true;
            		image.preloadObj.src = image.getAttribute("src");
            		image.preloadObj.removeEventListener("error", imageError4i);
            		image.preloadObj.addEventListener("error", imageError4i);
            		image.observer.unobserve(image);
        	}  
    	}
}
function imageError4i(e){	
	let img = e.currentTarget.parent;    	
	
	//webpNotSupported is a global variable set in VS
	if(typeof webpNotSupported !== 'undefined' && webpNotSupported && img.src.includes(".webp")){ 
		// switch all webp images to png or jpg before we set these image errors
		// switchWebpImage is a global variable in webpsupport.js which is ONLY loaded in VS (_broswersupport) if browser is old safari
		// Check if switchWebpImage is defined before calling it
	        if (typeof switchWebpImage !== 'undefined') {
	            	switchWebpImage(img);
	        } else {
	            	// If switchWebpImage is not defined, fallback to adding the image icon
	            	addImageIcon(img);
	        }
	}else{
		addImageIcon(img);
	}
}
function addImageIcon(image){
	let rules = window.getComputedStyle(image, null);
	let missingImgIcon = document.createElement("div");
	missingImgIcon.classList.add("missingImgSVG", "bkgdLtGray", "roundCorners3", "textCenter");
	missingImgIcon.innerHTML = '<svg class="textDarkestGray margin20" role="img" focusable="false" height="32px" width="32px" aria-label="New image coming soon"><use xlink:href="#svgIcon-artwork"></use></svg>';

	// go through the CSS properties on image element and add only the ones that are necessary for positioning to the missing image
	for(let i = 0; i < rules.length; i++){ 
		if(rules[i] == "position" || 
		  rules[i] == "bottom" || 
		  rules[i] == "left" || 
		  rules[i] == "top" || 
		  rules[i] == "right" ||
		  rules[i] == "display" ||
		  rules[i] == "vertical-align" ||
		  rules[i] == "margin-bottom" || 
		  rules[i] == "margin-left" || 
		  rules[i] == "margin-right" || 
		  rules[i] == "margin-top"){
			let rule = rules.item(i);
	   	  	if(rules.getPropertyValue(rule) !== "static")
	   			missingImgIcon.style.cssText += rule + ":" + rules.getPropertyValue(rule) + ";";
	   	}
	}
	
	image.style.display = "none";
	image.after(missingImgIcon);
}
function imagePreloader4i(event){
    	let preloadImageObj = event.target;
    	let image = preloadImageObj.parent;
      	image.src = preloadImageObj.src;
}

function bindImages4i(){
	let images4i = document.querySelectorAll("img");
	for(let image of images4i){
	    	image.observer = new IntersectionObserver(observeImageErrors, { rootMargin: "0px" });
	    	image.observer.observe(image);
	    	image.hasPreloader = false;
	    	image.isPNG = false;
             	image.isJPG = false;
	    	image.preloadObj = new Image();
	    	image.preloadObj.removeEventListener("load", imagePreloader4i);
	    	image.preloadObj.addEventListener("load", imagePreloader4i);
	    	image.preloadObj.parent = image;
	}  
}
  
bindImages4i();

/*const ImgError4i = (function() {
    	const bindImages = () => {
    		let allImages = document.getElementsByTagName("img");
    		for (let i = 0; i < allImages.length; i++) {
    			allImages[i].removeEventListener('error', imageError);
    			allImages[i].addEventListener('error', imageError);
		}
    	};
    	
    	const imageObserver = new IntersectionObserver(function(entries, observer) {
      		entries.forEach(function(entry) {
        		if (entry.intersectionRatio > 0) { //entry.isIntersecting
          			var image = entry.target;
          			image.onerror = () => { imageError(image); };
	          		imageObserver.unobserve(image);
	        	}
	      	});
	}, { rootMargin: "0px 0px 0px 0px" });
    	
    	const observeImgs = () => {
    		//bindImages();
		const callback = (mutationList, observer) => {
		  	for (let x = 0; x < mutationList.length; x++) {
		    		if (mutationList[x].type === "childList" && mutationList[x].target.getElementsByTagName("img")[0])
		      			bindImages();
		  	}
		};
		const observer = new MutationObserver(callback);
		const observeConfig = { childList: true, subtree: true };
		observer.observe(document.getElementsByTagName("body")[0], observeConfig);
		//observer.disconnect();
    	};
    	
    	const imageError = e => {
    		let image = e.currentTarget;
    		let rules = window.getComputedStyle(image, null);
    		let missingImgIcon = document.createElement("div");
    		
    		missingImgIcon.classList.add("bkgdLtGray", "roundCorners3", "textCenter");
    		missingImgIcon.innerHTML = '<svg class="textDarkestGray margin20" role="img" focusable="false" height="32px" width="32px" aria-label="New image coming soon"><use xlink:href="#svgIcon-artwork"></use></svg>';

		// go through the CSS properties on image element and add only the ones that are necessary for positioning to the missing image
		for(let i = 0; i < rules.length; i++){ 
			if(rules[i] == "position" || 
			  rules[i] == "bottom" || 
			  rules[i] == "left" || 
			  rules[i] == "top" || 
			  rules[i] == "right" ||
			  rules[i] == "margin-bottom" || 
			  rules[i] == "margin-left" || 
			  rules[i] == "margin-right" || 
			  rules[i] == "margin-top"){
				let rule = rules.item(i);
		   	  	if(rules.getPropertyValue(rule) !== "static")
		   			missingImgIcon.style.cssText += rule + ":" + rules.getPropertyValue(rule) + ";";
		   	}
		}

    		image.style.display = "none";
    		image.after(missingImgIcon);
	};
	
  	return{
  		BindImages: bindImages,
  		ObserveImgs: observeImgs
  	};
})();


ImgError4i.BindImages();

*/

/*
** SOMEDAY UPDATE TO USING JUST CSS IF BROWSERS SUPPORT IT
img { 
	position: relative; display: block;
	
	&::after {  
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: $full-percent;
		height: $full-percent;
		background-image: url(//cdn.4imprint.com/qtz/icons/svg/artwork/artwork.svg);
		background-size: 2rem 2rem;
		background-repeat: no-repeat;
		background-color: $ltGray;
		background-position: center;
	  	text-align: center;
	  	margin: 0 auto;
	}
}
*/