const header4i = function() {
	let userLinksWrapper = document.getElementById("userSpecificLinksBox");
	
	let init = function(){
		loadUserLinks();
        	HeaderView.Init();
        	
		window.addEventListener('appinstalled', function() {
  			//do the logging
		});			
	},
	
	HeaderView = (function(){
		let topMenuBar, menuBtn, headerLogo, stickyHead, mainHeader, mainContent,
		
		init = () => {
			topMenuBar = document.querySelector(".mainNavPlaceholder"); //TODO change this class
			stickyHead = document.querySelector(".headerUserLinksPlaceholder"); //TODO change this class
			menuBtn = document.querySelector(".headerMenuBtn");
			headerLogo = document.querySelector(".headerLogo").parentNode;
			mainHeader = document.querySelector("header");
			mainContent = document.getElementById("mainContent");
			
			document.querySelector(".skip").removeAttribute("style");
			mobileChanges();
			window.addEventListener("resize", mobileChanges);
			if(window.innerHeight > 500) window.addEventListener("scroll", makeSticky); // changed this to 500 to account for height of header plus cart dropdown
			makeSticky(); // if page is loaded already scrolled down a bit
		},
		mobileChanges = () => {
	        	if(window.innerWidth < 600 && !headerLogo.classList.contains("flexAlignItemsCenter")){
	                	headerLogo.classList.add("flexAlignItemsCenter");
	                	menuBtn.after(headerLogo);
	            	} else if(window.innerWidth >= 600 && headerLogo.classList.contains("flexAlignItemsCenter")) {
	                	headerLogo.classList.remove("flexAlignItemsCenter");
	                	headerLogo.after(menuBtn);
	        	}
	        	if(menuBtn.classList.contains("invisible")) menuBtn.classList.remove("invisible");
	        	if(headerLogo.classList.contains("invisible")) headerLogo.classList.remove("invisible");
	        	//if(mainHeader.classList.contains("invisible")) mainHeader.classList.remove("invisible");
	    	},
		addClickToCall = (s, s_account) => {
    			document.getElementById('callBtn').addEventListener("click", function (e) {
            			e.preventDefault();
            			AdobeLog.ClickToCall(this.getAttribute('data-adobe-v33'));
            			window.location.href=e.target.href;
       		});
		},
		makeSticky = () => { 
			if(window.scrollY > topMenuBar.offsetHeight){
				if(!stickyHead.classList.contains("main-nav-scrolled")) {
					stickyHead.classList.add("main-nav-scrolled");
					//if(mainContent) mainContent.classList.add("topMargin"); 
				}
			} else {
				if(stickyHead.classList.contains("main-nav-scrolled")){
					stickyHead.classList.remove("main-nav-scrolled");
					//if(mainContent) mainContent.classList.remove("topMargin"); 
				}
			}
		};
		return{
			Init: init,
			AddClickToCall: addClickToCall
		};
	})(),

    	AdobeLog = (function () {
	    	let clickToCall = (phNum) => {
	     	 	if(s){
	      		      	s.linkTrackVars="prop20,eVar20,eVar33,events"; 
	 	      		s.linkTrackEvents="event28";
		      		s.events='event28';
	            		s.eVar33= phNum;
	            		s.prop20='D=v33';
	            		s.pe='lnk_o';
	            		s.pev2='Click-to-Call';
		        	s.tl(true, 'o', 'Click-to-Call');
		        	s.linkTrackEvents='None';
		        	s.linkTrackVars='None';
	        	}
	    	},
	    	pwaInstall = () => {
	         	if(s){
	         		s.event30 = 'PWA Install';
	         		s.t();
	         	}
	       };
	    	return {
	        	ClickToCall: clickToCall,
	        	PwaInstall: pwaInstall
	    	};
	})(),

	fadeOut = (element) => {
		element.classList.remove("animateFadeIn");
		element.classList.add("animateFadeOut");
		setTimeout(() => {
			element.classList.add("hide");
		}, 400);
	},
	fadeIn = (element) => {
		element.classList.remove("animateFadeOut");
		element.classList.add("animateFadeIn");
		element.classList.remove("hide");
	},
	
	HeaderAccount = (function(){
		let accountView, accountLink, accountSVGs,
			
		init = () => {
			accountView = document.getElementById("hdrAccountMenu");
			accountLink = document.getElementById("hdrAccountBtn");	
			if(accountLink){
				accountSVGs = accountLink.querySelectorAll("svg");
			}
			if(accountView){
				accountLink.removeEventListener("click", showHide);
				accountLink.addEventListener("click", showHide);
				accountKeys();
			}
		},
		show = () => {	
			fadeIn(accountView);
			accountLink.setAttribute("aria-expanded", "true");
			if(accountSVGs[1]) accountSVGs[1].classList.remove("rotate180");
			
			document.addEventListener("keydown", escapeAccount);
			document.addEventListener("click", closeClickOutside);
		},
		hide = () => {
			fadeOut(accountView);
			accountLink.setAttribute("aria-expanded", "false");
			if(accountSVGs[1]) accountSVGs[1].classList.add("rotate180");
			
			document.removeEventListener("keydown", escapeAccount);
			document.removeEventListener("click", closeClickOutside);
		},
		showHide = (e) => {
			e.preventDefault();
			if(accountView.classList.contains("hide")) show(); 
			else hide(); 
		},
		closeClickOutside = (e) => {
			let accountWrapper = document.getElementById('hdrActWrapper');
			if (accountWrapper && !accountWrapper.contains(e.target)) hide();
		},
		escapeAccount = (e) => {
	    		if(e.keyCode === 27){ // allow user to close on escape
				hide();
				accountLink.focus();
	    		}
		},
		accountKeys = () => {
			if(accountView.querySelector("a") !== null){
				let accountDropLinks = [].slice.call(accountView.querySelectorAll("a")),
				lastLink = accountDropLinks[accountDropLinks.length -1];
				lastLink.addEventListener("keydown", function(e){							
					if(e.keyCode == 9 && lastLink == document.activeElement) hide();
				});
			}
		};
		return{
			Init: init
		};
	})(),
	
	HeaderCart = (function(){
		let cartView, cartSummary, checkoutLink,
			
		init = () => {
			cartView = document.getElementsByClassName("hdrCartMenu")[0];
			cartSummary = document.getElementById("hdrCartBtn");
			checkoutLink = document.getElementById("checkoutLink");	
			
			if(cartView){ // not an empty cart
				cartSummary.removeEventListener("click", showHide);
				cartSummary.addEventListener("click", showHide);
				cartKeys();
			}
		},
		show = () =>  { 
			fadeIn(cartView);
			cartSummary.setAttribute("aria-expanded", "true");	 		
			document.addEventListener("keydown", escapeCart);
			document.addEventListener("click", closeClickOutside);
		},
		hide = () =>  {
			fadeOut(cartView);
			cartSummary.setAttribute("aria-expanded", "false");
			
			document.removeEventListener("keydown", escapeCart);
			document.removeEventListener("click", closeClickOutside);
		},
		showHide = (e) => {
			e.preventDefault();
			if(cartView.classList.contains("hide")) show();
			else hide(); 
		},
		closeClickOutside = (e) => {
			let cartWrapperElement = document.getElementById('hdrCartWrapper');
			if (cartWrapperElement && !cartWrapperElement.contains(e.target)) hide();
		},
		escapeCart = (e) => {
	    		if(e.keyCode === 27){ // allow user to close on escape
				hide();
				cartSummary.focus();
	    		}
		},
		cartKeys = () => {
			checkoutLink.addEventListener("keydown", function(e){							
				if(e.keyCode == 9 && checkoutLink == document.activeElement) hide();
			});
		};
		return{
			Init: init
		};
	})(),
    	
	loadUserLinks = () => {
		if (userLinksWrapper == null) { return; }
		fetch('/Header/UserSpecificLinks', { 
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : ''; // response.text() returns a promise
		}).then(function(html){
			if(html.length < 1) { userLinksWrapper.innerHTML = ""; return; }
	    		userLinksWrapper.innerHTML = html;
			HeaderAccount.Init();
			HeaderCart.Init();
			if(document.getElementById('callBtn')) HeaderView.AddClickToCall();
			if(document.getElementById('seoBtn')){
				// bootload SEO bits here only when needed
				var seo = document.createElement('script'); 
				seo.type = 'text/javascript'; 
				seo.async = true; 
				seo.src = 'https://cdn.4imprint.com/qtz/views/seooverlay/seoeditor.js';
				var bodyEl = document.getElementsByTagName('body')[0]; 
				bodyEl.appendChild(seo);
			}
		}).catch(function(e){
			userLinksWrapper.innerHTML = ""; // remove loading icon
		});
	};
	
	return{
		Init: init,
		HeaderCart: HeaderCart
	};
};
(function(){
	const loadHeader = new header4i();
	loadHeader.Init();
})();